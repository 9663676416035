import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {LayoutModule} from "./shared/modules/layout/layout.module";
import {AuthGuard} from "./shared/auth/guards/auth.guard";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {JWT_OPTIONS, JwtModule} from "@auth0/angular-jwt";
import {CookieModule, CookieService} from "ngx-cookie";
import {TenantInterceptor} from "./tenant/tenant.interceptor";
import {AppRoutingModule} from "./app-routing.module";
import {TenantConfigService} from "./tenant/services/tenant-config.service";
import {ErrorInterceptorService} from "./shared/interceptors/error-interceptor.service";
import '@angular/localize/init';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {EnvironmentService} from "./shared/services/environment.service";
import {provideZxvbnServiceForPSM} from "angular-password-strength-meter/zxcvbn";
import {BugsnagErrorHandler} from "@bugsnag/plugin-angular";
import Bugsnag from "@bugsnag/js";
import BugsnagPerformance from "@bugsnag/browser-performance";
import {TenantService} from "./tenant/services/tenant.service";
import {GlobalErrorHandler} from "./shared/handlers/global-error.error-handler";

export function tenantConfigFactory(config: TenantConfigService) {
  return  () => config.load();
}

export function reCaptchaConfigFactory(env: EnvironmentService) {
  return env.tenantConfig?.recaptcha.sitekey;
}

export function jwtOptionsFactory(cookieService: CookieService) {
  return {
    tokenGetter: () => {
      return cookieService.get("access_token");
    },
    allowedDomains: [
      "api.devel.talentview.org",
      "api.talentview.org",
      "api.devel.destinationnuclearcareersportal.co.uk",
      "api.demo.plus.talentview.org",
      "api.phase2.destinationnuclearcareersportal.co.uk",
      "api.destinationnuclearcareersportal.co.uk"
    ],
  }
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory(env: EnvironmentService, config: TenantService) {

  // configure Bugsnag ASAP
  Bugsnag.start( {
    appVersion: '1.0.9',
    apiKey: '6ec30ecda24d60c248c0613333083342',
    releaseStage: env.environmentByHost(config.getTenant()),
    enabledReleaseStages: [ 'production', 'develop', 'phase2' ]
  });

  BugsnagPerformance.start({ apiKey: '6ec30ecda24d60c248c0613333083342' })

  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    CookieModule.withOptions(),
    LayoutModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    MatSnackBarModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CookieService]
      }
    })
  ],
  providers: [
    TenantConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: tenantConfigFactory,
      deps: [TenantConfigService],
      multi: true
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      deps: [EnvironmentService],
      useFactory: reCaptchaConfigFactory
    },
    {
      provide: ErrorHandler,
      deps: [EnvironmentService, TenantService],
      useFactory: errorHandlerFactory
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
